import "../../styles/Tailwind/MenuButtons.css";
export default function HamburgerButton({handleClick}: any){
  return (
    <button id="btn_open_menu" onClick={handleClick} className="btn_open_hamburger" title="Abrir menú">
      <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Icons" clipPath="url(#clip0_5335_640)">
          <path id="Vector" d="M4.5 27H31.5V24H4.5V27ZM4.5 19.5H31.5V16.5H4.5V19.5ZM4.5 9V12H31.5V9H4.5Z" fill="#F1EFE3"/>
        </g>
        <defs>
        <clipPath id="clip0_5335_640">
          <rect width="36" height="36" fill="white"/>
        </clipPath>
        </defs>
      </svg>
    </button>
  );
}
