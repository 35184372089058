"use client";
import { usePathname } from "next/navigation";
import "../../../styles/Tailwind/MenuTerms.css";
import InteractiveLink from "../BloqueHeroGrid/InteractiveLink";

type Props = {
	terms: any;
};

export default function MenuTerms({ terms }: Props) {
	const pathname = usePathname();

	if (pathname?.includes("/descubre/")) return null;

	const blockDataLayer = {
		event: "select_content",
		clickBloque: 0,
		colorBloque: undefined,
		content_name: "Menu>Categories>",
		content_type: "Button",
		contenidosBloque: undefined,
		countWidget: 1,
		descripcionBloque: undefined,
		estiloBloque: undefined,
		eventAction: "click",
		posicionBloque: 1,
		seccion: "",
		subBloque: undefined,
		tamanoBloque: undefined,
		tipoBloque: undefined,
		tipoElemento: "LinkText",
		tituloBloque: "",
	};
	return (
		<nav className="terms_nav">
			<ul className="terms_list">
				{terms?.map((item: any, i: number) => {
					blockDataLayer.seccion = item?.uri;
					return (
						<li key={`item-${item?.title}-${i}`} className="term_item">
							<InteractiveLink
								url={item?.uri ?? {}}
								title={item?.title}
								cardPosition={undefined}
								blockData={{
									...blockDataLayer,
									content_name: `Menu>Categories>${item?.title}`,
								}}
								isLink={true}
							>
								{item?.title}
							</InteractiveLink>
						</li>
					);
				})}
			</ul>
		</nav>
	);
}
