"use client";
import Link from "next/link";
import "../../../styles/Tailwind/MenuHeader.css";
import { use, useEffect, useReducer, useRef } from "react";
import CloseHamburgerButton from "../../atoms/CloseHamburgerButton";
import Image from "next/image";
import { useSelectedLayoutSegment } from "next/navigation";

type AppState = {
	openChannels: boolean;
	openLocals: boolean;
};
const initialState: AppState = {
	openChannels: false,
	openLocals: false,
};

type AppAction = { type: "Canales" } | { type: "NLocal" };
function reducer(state: AppState, action: AppAction): AppState {
	if (action?.type === "Canales") {
		return { ...state, openChannels: !state?.openChannels, openLocals: false };
	} else if (action?.type === "NLocal") {
		return { ...state, openLocals: !state?.openLocals, openChannels: false };
	}

	return { openChannels: false, openLocals: true };
}
export default function MenuHeader({ menu, isOpenMenu, setOpenMenu }: any) {
	const [state, dispatch] = useReducer(reducer, initialState);
	const menuRef = useRef<any>(null);
	const activeSegment = useSelectedLayoutSegment();
	const finalMenu = menu?.map((item: any) => {
		if (item?.subElements?.length > 0) {
			item?.subElements?.forEach((elem: any, j: number) => {
				if (elem?.value === "Guadalajara" || elem?.value === "Monterrey") {
					elem["link"] = `/${elem?.value?.toLowerCase()}`;
				}
			});
		}
		return item;
	});
	const blockDataLayer = {
		event: "select_content",
		clickBloque: 0,
		colorBloque: undefined,
		content_name: "Menu>Secciones",
		content_type: "Button",
		contenidosBloque: undefined,
		countWidget: 1,
		descripcionBloque: undefined,
		estiloBloque: undefined,
		eventAction: "click",
		posicionBloque: undefined,
		seccion: "",
		subBloque: undefined,
		tamanoBloque: undefined,
		tipoBloque: undefined,
		tipoElemento: "LinkText",
		tituloBloque: undefined,
	};

	const handleOutClick = (e: any) => {
		if (menuRef.current) {
			if (!menuRef?.current?.contains(e.target))
				setOpenMenu(0, false, blockDataLayer, "CloseMenu");
		}
	};

	useEffect(() => {
		if (isOpenMenu) {
			document.addEventListener("click", handleOutClick);
		} else {
			document.removeEventListener("click", handleOutClick);
		}

		return () => {
			document.removeEventListener("click", handleOutClick);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpenMenu]);

	return (
		<nav
			aria-label="Menú Principal"
			className={`nav_hamburger ${isOpenMenu ? "" : "closed"}`}
		>
			{isOpenMenu && (
				<CloseHamburgerButton
					handleClick={() => setOpenMenu(0, false, blockDataLayer, "CloseMenu")}
				/>
			)}
			<ul className="menu_list" ref={menuRef}>
				{finalMenu?.map((item: any, index: any) => {
					const btnId = item?.value
						.toLowerCase()
						.replace("+", "")
						.replace(" ", "");
					return (
						<li key={`HHader-4${index}`} className="menu_item">
							{item?.subElements?.length > 0 ? (
								<>
									<button
										role="button"
										id={
											btnId === "canales"
												? "idCanales"
												: "idLocales"
										}
										onClick={() =>
											dispatch({
												type: item?.value.replaceAll(" ", "").replace("+", ""),
											})
										}
										className={
											state?.openChannels && !state?.openLocals
												? "chOpenArrow"
												: state?.openLocals && !state?.openChannels
												? "locOpenArrow"
												: ""
										}
									>
										{item?.value}
									</button>
									<ul
										className={`sub_list ${item?.value
											.replaceAll(" ", "")
											.replace("+", "")} ${
											state?.openChannels
												? "openCanales"
												: state?.openLocals
												? "openNLocal"
												: ""
										}`}
									>
										{item?.subElements?.map((subItem: any) => {
											return (
												<li
													key={subItem?.id}
													className="sub_menu_item"
													onClick={() =>
														setOpenMenu(
															index + 1,
															false,
															blockDataLayer,
															subItem?.value
														)
													}
												>
													{item?.link === "/canales" ? (
														<Link
															href={subItem?.link}
															prefetch={false}
															className={
																activeSegment === subItem?.link.replace("/", "")
																	? "activeImg"
																	: ``
															}
														>
															<Image
																src={`/assets/logosChannel${subItem?.link}.svg`}
																alt={subItem?.value}
																width="40"
																height="40"
																loading="eager"
															/>
														</Link>
													) : (
														<Link
															href={subItem?.link}
															prefetch={false}
															className={
																activeSegment === subItem?.link.replace("/", "")
																	? "activeSub"
																	: ``
															}
														>
															{subItem?.value}
														</Link>
													)}
												</li>
											);
										})}

										{item?.link === "/noticias-locales/programas" && (
											<li
												key={"verTodos"}
												className="sub_menu_item"
												onClick={() =>
													setOpenMenu(
														index + 1,
														false,
														blockDataLayer,
														"VerTodos"
													)
												}
											>
												<Link href={"/noticias-locales/programas"} prefetch={false}>
													VER TODOS
												</Link>
											</li>
										)}
									</ul>
								</>
							) : (
								<Link
									href={item?.link?.replace("home", "")}
									prefetch={false}
									title={item?.value}
									onClick={() =>
										setOpenMenu(index + 1, false, blockDataLayer, item?.value)
									}
									className={
										activeSegment === item?.link.replace("/", "") ||
										String(activeSegment) ===
											`${
												item?.link === "/home" || item?.link === ""
													? null
													: item?.link
											}`
											? "active"
											: ``
									}
								>
									{item?.value}
								</Link>
							)}
						</li>
					);
				})}
			</ul>
		</nav>
	);
}
